<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>

    <div class="bottomCss">
      <div id="chuneng" class="chanpingCss">
        <div class="xinwen">
          <div class="title">
            首次“新能源产业及应用”专题援外培训顺利完成
          </div>
          <div class="time">2023/06/20</div>
          <div class="zhengwen">
            由中华人民共和国商务部主办，中国信科集团承办的发展中国家援外培训班于今年6月顺利启动。近日，“物联网技术及应用研修班”、“移动互联网发展研修班”和“下一代通信网络建设研修班”陆续在汉举行隆重的开班典礼。本次研修班，学员分别来自阿塞拜疆、老挝、蒙古、伊拉克、乌兹别克斯坦、亚美尼亚、尼泊尔、埃及、突尼斯、加纳、尼日利亚、苏里南、摩洛哥、莱索托、牙买加、古巴、萨摩亚共18个发展中国家的内政部、通信部、科技部、电信局、国家电视台、高校和相关企业，含4位司局级、37位处级和多位高级管理和技术人员。
          </div>
          <div style="text-align: center;margin-top: 42px;">
            <img class="imgCss" style="width: 40%;" src="./img/援外1.png" alt="" />
            <img class="imgCss" style="width: 40%;" src="./img/援外1.png" alt="" />
          </div>
          <div class="zhengwen">
            6月19日，烽火富华受邀完成了主题为“新能源产业及应用”的专题课程，公司作为湖北省新能源行业领军企业，非常重视援外培训工作，相关团队全力配合烽火学院完成了专题讲座、课堂讨论、工厂参观等多种形式的教学内容，以期参训学员们能拥有良好的学习体验。
          </div>
          <div style="text-align: center;margin-top: 42px;">
            <img class="imgCss" style="width: 40%;" src="./img/援外2.jpg" alt="" />
            <img class="imgCss" style="width: 40%;" src="./img/援外3.jpg" alt="" />
          </div>
          <div class="zhengwen">
            专题讲座主要介绍了新能源技术的分类和发展，并针对居民和工商业应用场景重点介绍了储能及充电桩相关产品及解决方案。参训学员在培训过程中不时拍照记录相互讨论，表现出极大的兴趣，并在互动环节与富华培训人员展开了深入交流。
          </div>
          <div style="text-align: center;margin-top: 42px;">
            <img class="imgCss" style="width: 40%;" src="./img/援外4.jpg" alt="" />
            <img class="imgCss" style="width: 40%;" src="./img/援外5.jpg" alt="" />
          </div>
          <div class="zhengwen">
            来自牙买加布里奇电视台的SHAWN FERGUSON详细咨询了烽火富华最新一代高压液冷超充的相关参数，对于其充电五分钟能续航两百公里的性能表示赞叹。
          </div>
          <div style="text-align: center;margin-top: 42px;">
            <img class="imgCss" style="width: 40%;" src="./img/援外6.jpg" alt="" />
            <img class="imgCss" style="width: 40%;" src="./img/援外7.jpg" alt="" />
          </div>
          <div class="zhengwen">
            讲座结束后还安排全体人员前往烽火富华位于江夏的制造中心进行参观考察，详细了解公司各种新能源技术及产品解决方案，相信此次培训会给各位学员留下深刻的印象。
          </div>
          <div style="text-align: center;margin-top: 42px;">
            <img class="imgCss" style="width: 40%;" src="./img/援外.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0, //选中项
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  padding-bottom: 88px;
  background: #f2f5f8;
}
.chanpingCss {
  padding-top: 72px;
  display: flex;
  /* text-align: center; */
  flex-direction: column;
  align-items: center;
}
.xinwen {
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1314px;
  /* height: 360px; */
  background: #ffffff;
  margin-bottom: 16px;
  /* margin-top: 72px; */
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  
}
.time {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16px;
  margin-bottom: 42px;
}
.imgCss {
  width: 800px;
  /* height: 600px; */
}
.zhengwen {
  text-indent: 2em;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  margin-top: 36px;
}
.xioabioti{
  text-indent: 2em;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 38px;
  margin-top: 36px;
  width: 100%;
    float: left;
}
</style>